import React from "react";

import PageWrapper from "../components/PageWrapper";
import ContentThree from "../sections/home4/ContentOne";
import { Link } from "gatsby";

const CareerDetails = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a href="https://auth.monday.com/oauth2/authorize?client_id=b1800c5eb4bf5d826c027a646f9b6130&response_type=install">
                <img
                  alt="Add to monday.com"
                  height="60"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                />
              </a>
            </>
          ),
          footerStyle: "style4",
        }}
      >
        <main className="pt-23 pt-md-27 pb-13 pb-md-19 pt-lg-29 pb-lg-27">
          <ContentThree className="pb-13 pb-lg-34"/>
        </main>
      </PageWrapper>
    </>
  );
};
export default CareerDetails;
